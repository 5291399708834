import React, { useEffect } from 'react';
import Helmet from 'react-helmet';

import App from '../../../components/layout/App';

const JobsPage = (props) => {
  useEffect(() => {
    if (window._grnhse) {
      window._grnhse.load();
    } else {
      setTimeout(() => {
        if (window._grnhse) {
          window._grnhse.load();
        }
      }, 2000);
    }
  }, []);

  return (
    <App displayGettingStartedPanel={false}>
      <div className="component jobs-page">
        <Helmet title="Jobs – ThinkReservations">
          <meta
            name="description"
            content="Join our team of passionate, experienced professionals and help our customers succeed!"
          />
        </Helmet>

        <div id="grnhse_app"></div>
      </div>
    </App>
  );
};

export default JobsPage;
